import React from "react";
import "./header.css";
import logo from "./logo.png";

export default class Header extends React.Component {
  render() {
    return (
      <>
        <div id="background"/>
        <h1 id="logo">
          <img src={logo} alt="Útkeresés Háza"/>
        </h1>

        <nav id="nav">
          <ul>
            <li>
              <a href="#programok">Programok</a>
            </li>
            <li>
              <a href="#magunkról">Magunkról</a>
            </li>

            <li>
              <a href="#tevékenységeink">Tevékenységeink</a>
            </li>
            <li>
              <a href="#lelkiség">Lelkiség</a>
            </li>
            <li>
              <a href="#támogatás">Támogatás</a>
            </li>
            <li>
              <a href="#kapcsolat">Kapcsolat</a>
            </li>
          </ul>
        </nav>
      </>
    );
  }
}
