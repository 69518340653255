import React from "react";
import "./section.css";

export default class Section extends React.Component {
  render() {
    return (
      <section id={this.props.id}>
        <h2>{this.props.title}</h2>
        <div className="content">{this.props.children}</div>
      </section>
    );
  }
}
