import React from "react";
import "./munkatars.css";

export default class Munkatars extends React.Component {
  render() {
    return (
      <div className="munkatars">
        <img src={this.props.img} />
        <span className="name">
          {this.props.name} - <span className="nickName">{this.props.nickName}</span>
        </span>
      </div>
    );
  }
}
