import React from "react";
import Section from "./Section";
import Munkatars from "./munkatarsak/Munkatars";
import Program from "./programok/Program";
import "./content.css";

import mari from "./munkatarsak/mari.jpg";
import andras from "./munkatarsak/andras.jpg";
import emi from "./munkatarsak/emi.jpg";

const keszthely_felev = "/keszthely_felev.pdf";
const keszthely_felev_thumbnail = "/keszthely_felev-200.png";
const keszthely_felev_thumbnail_webp = "/keszthely_felev-200.webp";

const reggeli_szemlelodes = "/reggeli_szemlelodes.pdf";
const reggeli_szemlelodes_thumbnail = "/reggeli_szemlelodes-200.png";
const reggeli_szemlelodes_thumbnail_webp = "/reggeli_szemlelodes-200.webp";

const csendes_ima = "/csendes_ima.pdf";
const csendes_ima_thumbnail = "/csendes_ima-200.png";
const csendes_ima_thumbnail_webp = "/csendes_ima-200.webp";

const kreativ_ima = "/kreativ_ima.pdf";
const kreativ_ima_thumbnail = "/kreativ_ima-200.png";
const kreativ_ima_thumbnail_webp = "/kreativ_ima-200.webp";

const utkereses_eve = "/utkereses_eve.pdf";
const utkereses_eve_thumbnail = "/utkereses_eve-200.png";
const utkereses_eve_thumbnail_webp = "/utkereses_eve-200.webp";

const feluton = "/feluton.pdf";
const feluton_thumbnail = "/feluton-200.png";
const feluton_thumbnail_webp = "/feluton-200.webp";

const keszthely = "/keszthely.pdf";
const keszthely_thumbnail = "/keszthely-200.png";
const keszthely_thumbnail_webp = "/keszthely-200.webp";

const nyilatkozat1 = "/2017_PK.pdf";
const nyilatkozat2 = "/16KOZ_BUE.pdf";
const nyilatkozat3 = "/2018_PK.pdf";
const nyilatkozat4 = "/17KOZ_BUE.pdf";

export default function Content() {
  return (
    <div id="content">
      <Section title="Támogatás" id="támogatás">
        <p>
          Kérjük ha teheti, támogassa adója 1%-ával a Benső Út Egyesület - Útkeresés Háza munkáját! Ezzel Ön is segíti
          az Útkeresés Háza további működését: az Útkeresés Éve program folytatását, számos más rendezvény és egyéni
          lelki vezetés szervezését. Minden egyes felajánlást szívből köszönünk!
        </p>
        <p>
          Benső Út Egyesület <br/>
          Adószám: 18262823-1-43 <br/>
          Számlaszám: 11711041-20867061
        </p>
      </Section>

      <Section title="Programjaink" id="programok">
        <h3>2020 évi programjaink</h3>
        <div>
          <Program href="https://www.facebook.com/events/2539389643045942/" thumbnail="/online_mise-200.png">
            Online szentmise Útkeresőknek és barátainknak Nagy Bálint SJ-vel
          </Program>

          <Program href="https://www.facebook.com/events/2583058705242443/" thumbnail="/szentharomnap-200.png">
            Szent Három Nap - Online lelki műhely
          </Program>
        </div>

        <h3>2019-2020 tanévi programjaink</h3>
        <div>
          <Program pdf="/2019_ev.pdf" thumbnail="/2019_ev-200.png">
            Útkeresés éve
          </Program>
        </div>
      </Section>

      <Section title="Magunkról" id="magunkról">
        <h3>Mi az Útkeresés háza?</h3>
        <p>
          Az Útkeresés Háza élő kövekből álló szellemi építmény. Olyan keresztény és istenkereső fiatalokból létrejött
          közösség, akik Isten vezetésével egymást segítve keresik egyéni útjukat. Ennek ad formát az Útkeresés Háza a
          Szent Ignác-i lelkiség eszközeivel, önismereti, dramatikus gyakorlatokkal, csönddel, szemlélődő imádsággal,
          megosztássokkal, lelki kíséréssel, szolgálati lehetőséggel. Segíti a közösség tagjait egy mélyülő
          istenkapcsolat és a hiteles, szabadon megélt emberi kapcsolatok felé. Célunk, hogy a fiatalok a lelkiségi és
          önismereti témákkal való foglalkozás során felfedezzék benső erőforrásaikat, amikből meríteni tudnak az
          elköteleződés, pályaválasztás folyamatában és megtalálják helyüket a világban.
        </p>
        <h3>Kik vagyunk?</h3>
        <div>
          <Munkatars img={mari} name="Bayer Róbertné Mária" nickName="Mari"/>
          <Munkatars img={andras} name="Koncz András" nickName="András"/>
          <Munkatars img={emi} name="Vigh Noémi" nickName="Émi"/>
        </div>
      </Section>

      <Section title="Tevékenységeink" id="tevékenységeink">
        {/* TODO Kiírni a lelkivezetést*/}
        <h3>Útkeresés Éve</h3>
        <p>
          Közösségi lelkigyakorlatos év fiataloknak. Egy év Istennek, másoknak és magamnak. Persze minden év ilyen, de
          ez kicsit másképp. Mind közül ez a legnagyobb szabású programunk: tíz hónapon át közös esték
          lelkiségi-önismereti témákkal, reggeli szemlélődések, lelkinapok, önkéntesség és egyéni lelkivezetés
          alkotják az év gerincét. A programot az is színesíti, hogy néhányan a fiatalok közül együtt is élnek kisebb
          lakóközösségekben, míg mások otthonról járnak be a közös alkalmakra.
        </p>

        <h3>Nyitott lelki napok</h3>
        <p>
          Időnként szükségünk van a kiszakadásra. Nyitott lelki napjainkon arra adunk lehetőséget, hogy a természetet
          járva, vagy egy vidéki helyre elvonulva az imádságon, csöndön, tematikus gyakorlatokon keresztül bárki
          felfedezhesse, mi zajlik a szíve mélyén. A figyelő jelenlét segít felszínre hozni emberi vágyainkat,
          érzéseinket, álmainkat, melyeken keresztül Isten szólongat mindannyiunkat.
        </p>

        <h3>Útkeresős mise</h3>
        <p>
          Szeretettel hívunk titeket havonta egy közös misére, ami után lehetőség nyílik a kötetlen beszélgetésre,
          találkozásra egykori útkeresőkkel, vagy éppen becsöppenhettek jövevényként is, hogy megismerjétek ezt a
          közösséget. Ha hoztok valamit a közösbe, akkor vacsorázhatunk is együtt. Általában minden hónap első
          vasárnapján 18 órától a Karolina úti lakásban.
        </p>

        <h3>Szemlélődés a kertben</h3>
        <p>
          A hétköznapi tevékenységből kizökkenve a lényünk legmélye felé figyelve találkozhatunk mindazzal, ami/aki
          magára vonja a figyelmünket és kitehetjük magunkat Isten kiáradó, teremtő, gyógyító, éltető szeretetének. A
          szentírás történetein keresztül formálódhatunk magunk is az Élet útján. Csütörtök este Katona Mária; péntek
          reggel Bayer Mária.
        </p>

        <h3>Vezetett Szent Ignác-i ima</h3>
        <p>
          Csendes benső imára hívjuk azokat a lélekben fiatalokat, akik Szent Ignác imamódjait követve keresik a
          bennük imádkozó Lélek hangját. Az imaóra menete: bejelentkező kör, csendes vezetett ima, megosztás. Akár
          egyetlen találkozás is fontos lehet Istennek!
        </p>

        <h3>Osztó-pontok: A pszichológus és a teológus beszélget</h3>
        <p>
          Az Osztó-Pontok egy, az Útkeresés Háza által szervezett érdekfeszítő és hiánypótló programsorozat. A havonta
          megrendezett kerekasztal beszélgetéseken két hozzáértő szakember, egy pszichológus és egy teológus osztja
          meg velünk saját nézőpontját, szemléletét az adott témában, a jelenlévőket pedig arra hívja, hogy
          elgondolkodjanak ezeken a néha megosztó, néha éppen összehozó kérdéseken.
        </p>

        <h3>Az Útkeresés Napja</h3>
        <p>
          Minden évben egyszer tartunk egy nyílt napot gyönyörű környezetben, árnyas fák alatt. Ezen a napon
          összesűrítve megmutatjuk, hogy kik vagyunk. Délelőtt és délután interaktív lelkiségi műhelyek, imádságok,
          kerekasztal beszélgetés várja az érdeklődőket, este szabadtéri szentmise, bográcsozás és táncház. Igazi
          ünneplés ez. Hálaadás a Teremtőnek, aki utat ad, és vezeti lépteinket.
        </p>

        <h3>Lelkigyakorlat a hétköznapokban</h3>
        <p>
          Szeretnél Istennel mélyebb kapcsolatba kerülni? Vágysz-e mélyebben felfedezni Őt mindennapi életed
          körülményeiben? Szeretnéd-e döntésed az Ő akarata szerint meghozni? Kész vagy-e naponta átengedni magad az Ő
          vonzásának, alakításának, éltető leheletének, és ebben a tapasztalatban egy kísérő segítségét elfogadni? Ha
          igen, akkor ez a kaland neked való. A Szent Ignác-i Lelkigyakorlat témáit követve a napi egyéni imádság
          kéthetente reggeli imádsággal és megosztással, kéthetente személyes kíséréssel egészül ki.
        </p>

        <h3>Radvány kerti kaláka</h3>
        <p>
          Van egy kertünk. Közösen műveljük. Havonta egy-két alkalommal összejövünk és ültetünk, gyomlálunk, ápoljuk a
          virágokat, és ha úgy adódik, örömmel szüreteljük a gyümölcsöket. A kerti munka általában kiegészül közös
          imával, étkezéssel és megosztással. Érdekes tapasztalatunk, hogy miközben a kezünk jár, és néha megizzadunk,
          valami belül is megmozdul és növekedésnek indul. Mintha bennünk is lenne egy kert…
        </p>

        <h3>Sport</h3>
        <p>
          Ép testben ép lélek. Van, hogy spontán kimozdulunk, frizbizünk egyet a Margitszigeten, vagy kosarazunk a
          Diószegi úton a játszótéren. Információkról érdeklődj bátran az utkereseshaza@gmail.hu címen.
        </p>
      </Section>

      <Section title="Lelkiség" id="lelkiség">
        <h3>Mélyből fakadó lelkiség</h3>
        <p>
          A mélyből fakadó (alulról induló) lelkiség a valóságra alapoz. Ha el tudjuk fogadni, hogy Isten a legbenső
          vágyainkon, érzéseinken, álmainkon keresztül keresi az utat hozzánk, az meg tud szabadítani az ideálok és
          elvárások bénító terhétől. A mélyből fakadó lelkiség a teremtettség szabadságának örömhírét jelenti.
          Bizalmat abban, hogy Isten már mindennel megajándékozott, ami ahhoz kell, hogy kapcsolatba léphessek Ővele.
          Én, mindazzal, amim van, és ami vagyok, elég vagyok Neki.
        </p>

        <h3>Önismeret</h3>
        <p>
          Az Útkeresésben újra és újra azt tapasztaljuk meg, hogy önmagunk megismeréséhez az út Istenen át vezet.
          Mindannyiunkban van egy darab a Teremtőből. Ha figyelünk befelé, önmagunkra, egyszer csak felfedezhetjük,
          hogy a törékenységünk, sebeink és álarcaink mögött az Ő Lelke valóban bennünk él, és „örök életre szökellő
          forrásra” (Jn 4,14) lelhetünk.
        </p>

        <h3>Közösség</h3>
        <p>
          Nagy öröm megtapasztalni, hogy Isten felé tartó utunkon nem vagyunk egyedül. Társakat ad mellénk az Úr, akik
          kérdeznek, visszajeleznek, támogatnak, kísérnek és néha kihívások elé állítanak. Az Útkeresésben fontos
          kincsünk, hogy együtt imádkozunk, beszélgetünk, és így néha megtapasztalhatjuk, hogy nem csak magányosan,
          bizonytalanul lépegetünk a jövő felé, hanem egyenesen visznek minket.
        </p>

        <h3>Ítéletmentes, szerető elfogadás</h3>
        <p>
          Ez a hozzáállás abból indul ki, hogy Isten mindig ott és úgy szólít meg bennünket, ahogy vagyunk. Istennel
          való kapcsolatunkban nem a teljesítmény a fontos, vagy az, hogy többet imádkozzunk vagy jobban viselkedjünk.
          A mi dolgunk sokkal inkább a figyelem. Annak felismerése, van Valaki, aki “előbb szeretett minket” (1Jn
          4,19), és mindig, minden helyzetben a nevünkön szólít a szívünk mélyén.
        </p>
      </Section>

      <Section title="Kapcsolat" id="kapcsolat">
        <h3>Elérhetőségek</h3>
        <p>
          Bayer Róbertné Mari +36/30-4551969 <br/>
          <a href="mailto:utkereseshaza@gmail.com">utkereseshaza@gmail.com</a> <br/>
          <a href="https://www.facebook.com/utkereseshaza/">facebook</a>
        </p>

        <h3>Barátaink, támogatóink</h3>
        <p>
          Magyar Jezsuita Rendtartomány <br/>
          Manréza Lelkigyakorlatos Ház – Dobogókő <br/>
          Élet Háza – Csobánka <br/>
          Háló Közösségfejlesztő Katolikus Egyesület <br/>
          Viator Egyesület – Lélektúrák
        </p>

        <h3>Nyilatkozatok</h3>
        <p>
          <a href={nyilatkozat1}>
            A kettős könyvvitelt vezető egyéb szervezet egyszerűsített éves beszámolója és közhasznúsági melléklet - 2017. év
          </a>
          <br/>
          <a href={nyilatkozat2}>
            Közlemény az adózó rendelkezése szerint a kedvezményezett részére átutalt összeg felhasználásáról
          </a>
          <br/>

          <a href={nyilatkozat3}>
            A kettős könyvvitelt vezető egyéb szervezet egyszerűsített éves beszámolója és közhasznúsági melléklet - 2018. év
          </a>
          <br/>
          <a href={nyilatkozat4}>
            Közlemény az adózó rendelkezése szerint a kedvezményezett részére átutalt összeg felhasználásáról
          </a>
        </p>
      </Section>
    </div>
  );
}
