import React from "react";
import Header from "./Header";
import Content from "./Content";
import "./app.css";

export default class App extends React.Component {
  render() {
    return (
      <div id="app">
        <Header />
        <Content />
      </div>
    );
  }
}
