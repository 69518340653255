import React from "react";
import "./program.css";

export default class Program extends React.Component {
  render() {
    const pdf = process.env.PUBLIC_URL + this.props.pdf;
    const href = this.props.href || pdf;
    return (
      <div>
        <a href={href} className="program">
          <img src={process.env.PUBLIC_URL + this.props.thumbnail} alt={this.props.title} />
          <div>{this.props.children}</div>
        </a>
      </div>
    );
  }
}
